import React, { Component } from "react";
import LoginIndex from "../Auth/LoginIndex";
import RegisterIndex from "../Auth/RegisterIndex";
import ForgotPassword from "../Auth/ForgotPassword";
import { connect } from "react-redux";

import { displayLogin, displaySignup } from "../../store/actions/CommonAction";

const $ = window.$;

class HomeIndex extends Component {
  handleLogin = (event) => {
    event.preventDefault();
    this.props.dispatch(displayLogin());
  };
  handleSignup = (event) => {
    event.preventDefault();
    this.props.dispatch(displaySignup());
  };

  showSignup = (event) => {
    event.preventDefault();
    this.props.dispatch(displaySignup());
  };

  render() {
    return (
      <>
        <section class="sm-padding">
          <div class="banner-sec sm-padding">
            <div class="container">
              <div class="row">
                <div class="col-md-6 resp-sm-mrg-btm">
                  <div class="banner-content">
                    <h1>
                      Welcome <span class="primary-txt">Back</span>
                    </h1>
                    <p>
                      Discover the different ways for you to get the most out of
                      your PayPal account. Take charge of your money and
                      purchases today.
                    </p>
                    <p>
                      Eros in cursus turpis massa. Suspendisse ultrices gravida
                      dictum fusce. Dolor sit amet consectetur adipiscing elit
                      duis tristique sollicitudin. Fames ac turpis egestas
                      integer eget aliquet.
                    </p>
                    <a class="btn btn-primary" href="#">
                      View More
                    </a>
                  </div>
                </div>
                <div class="col-md-6">
                  <img
                    src={window.location.origin + "/assets/img/banner-img.svg"}
                    class="banner-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="features-sec sm-padding">
          <div class="container">
            <div class="row">
              <div class="col-md-12 text-center">
                <h2 class="title">Our Features</h2>
                <img
                  src={
                    window.location.origin + "/assets/img/title-underline.svg"
                  }
                  class="title-border"
                />
              </div>
            </div>
            <div class="space-mg"></div>
            <div class="row">
              <div class="col-md-4 resp-sm-mrg-btm">
                <div class="features-box">
                  <div class="features-img">
                    <img
                      src={
                        window.location.origin + "/assets/img/features-1.svg"
                      }
                      class="f-img"
                    />
                  </div>
                  <div class="features-details">
                    <h3>Full Encrypted</h3>
                    <p>
                      Erat Velit Scelerisque In Dictum Lacus Sed Viverra Tellus
                      In Hac. Lorem Sed Risus Duis Ut Diam Quam Nulla Porttitor
                      Massa Id Neque
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 resp-sm-mrg-btm">
                <div class="features-box">
                  <div class="features-img">
                    <img
                      src={
                        window.location.origin + "/assets/img/features-2.svg"
                      }
                      class="f-img"
                    />
                  </div>
                  <div class="features-details">
                    <h3>Safe And Secure</h3>
                    <p>
                      Erat Velit Scelerisque In Dictum Lacus Sed Viverra Tellus
                      In Hac. Lorem Sed Risus Duis Ut Diam Quam Nulla Porttitor
                      Massa Id Neque
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="features-box">
                  <div class="features-img">
                    <img
                      src={
                        window.location.origin + "/assets/img/features-3.svg"
                      }
                      class="f-img"
                    />
                  </div>
                  <div class="features-details">
                    <h3>Instant Cashout</h3>
                    <p>
                      Erat Velit Scelerisque In Dictum Lacus Sed Viverra Tellus
                      In Hac. Lorem Sed Risus Duis Ut Diam Quam Nulla Porttitor
                      Massa Id Neque
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="aboutus-sec sm-padding">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <h4 class="title">
                  Easy Payment To Borrow Free Get
                  <br />A Better Filling At Home
                </h4>
                <img
                  src={
                    window.location.origin + "/assets/img/title-underline.svg"
                  }
                  class="title-border"
                />
                <div class="desc">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusd tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusd tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <p>
                    But I must explain to you how all this mistaken idea of
                    denouncing pleasure and praising pain was born and I will
                    give you a complete account of the system, and expound the
                    actual teachings of the great explorer.
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <img
                  src={window.location.origin + "/assets/img/aboutus-img.svg"}
                  class="banner-img"
                />
              </div>
            </div>
          </div>
        </section>

        <section class="calltoaction-sec sm-padding">
          <div class="container">
            <div class="c-box">
              <div class="row">
                <div class="col-md-6">
                  <h3 class="title">Have a business account?</h3>
                </div>
                <div class="col-md-6 align-right">
                  <a
                    class="btn btn-primary"
                    data-toggle="modal"
                    data-target=".signup"
                    href="#"
                    onClick={this.showSignup}
                  >
                    Create an Account
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="aboutus-sec sm-padding">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <img
                  src={window.location.origin + "/assets/img/aboutus-img.svg"}
                  class="banner-img"
                />
              </div>
              <div class="col-md-6">
                <h4 class="title">
                  Easy Payment To Borrow Free Get
                  <br />A Better Filling At Home
                </h4>
                <img
                  src={
                    window.location.origin + "/assets/img/title-underline.svg"
                  }
                  class="title-border"
                />
                <div class="desc">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusd tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusd tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <p>
                    But I must explain to you how all this mistaken idea of
                    denouncing pleasure and praising pain was born and I will
                    give you a complete account of the system, and expound the
                    actual teachings of the great explorer.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <LoginIndex />
        <RegisterIndex />
        <ForgotPassword />
      </>
    );
  }
}

const mapStateToPros = (state) => ({
  common: state.common,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(HomeIndex);
