import React, { Component } from "react";
import BusinessDetailsModel from "./BusinessDetailsModal";
import { connect } from "react-redux";
import CreateInvoiceTableSec from "./CreateInvoiceTableSec";
import {
  saveBusinessDetailsStart,
  fetchBusinessDetailsStart,
} from "../../../store/actions/BusinessDetailsAction";
import CreateInvoiceRightSec from "./CreateInvoiceRightSec";
import {
  saveInvoiceDetailsStart,
  saveInvoiceItemsStart,
  updateInvoiceItemsArray,
  deleteInvoiceItemsStart,
  fetchSingleInvoiceDetailsStart,
} from "../../../store/actions/CreateInvoiceAction";
import { Prompt } from "react-router";
import { Beforeunload } from "react-beforeunload";
import { Link } from "react-router-dom";
import { searchUserStart } from "../../../store/actions/SendMoneyAction";

const $ = window.$;

class CreateInvoiceIndex extends Component {
  state = {
    businessDetailsInput: {},
    invoiceInput: {},
    imagePreviewUrl: null,
    invoiceItemsArray: {},
    invoiceItemInput: {
      name: "",
      amount: "",
      quantity: "",
      generated_invoice_id: "",
    },
    tableLength: 1,
    updatedItemInput: {},
    selectedItem: null,
    selectedItemInput: {
      name: "",
      amount: "",
      quantity: "",
      generated_invoice_item_id: "",
    },
    searchUser: "",
    displayUserList: "",
    toUserEmail: "",
    ccUserEmail: "",
    displayCCUserList: "",
  };

  componentDidMount() {
    this.props.dispatch(fetchBusinessDetailsStart());
  }

  updateBusinessDetails = (event) => {
    this.setState({
      businessDetailsInput: this.props.businessDetails.businessDetailsInput
        .data,
    });
  };

  getBusinessDetailsData = ({ currentTarget: input }) => {
    const businessDetailsInput = { ...this.state.businessDetailsInput };
    businessDetailsInput[input.name] = input.value;
    this.setState({ businessDetailsInput });
  };

  saveBusinessDetails = (event) => {
    event.preventDefault();
    const businessDetailsInput = { ...this.state.businessDetailsInput };
    if (this.props.createInvoice.generatedInvoiceId != null) {
      businessDetailsInput[
        "generated_invoice_id"
      ] = this.props.createInvoice.generatedInvoiceId;
      this.setState({ businessDetailsInput });
    }
    setTimeout(() => {
      this.props.dispatch(
        saveBusinessDetailsStart(this.state.businessDetailsInput)
      );
    }, 500);

    $(".edit-business-modal").modal("hide");
  };

  getInvoiceInput = ({ currentTarget: input }) => {
    const invoiceInput = { ...this.state.invoiceInput };
    invoiceInput[input.name] = input.value;
    this.setState({ invoiceInput });
  };

  handleChangeImage = ({ currentTarget: input }) => {
    const invoiceInput = { ...this.state.invoiceInput };
    if (input.type === "file") {
      invoiceInput[input.name] = input.files[0];
      this.setState({ invoiceInput });
    }
    let reader = new FileReader();
    let file = input.files[0];

    reader.onloadend = () => {
      if (input.name == "logo")
        this.setState({
          imagePreviewUrl: reader.result,
        });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  saveInvoiceDetails = (event) => {
    event.preventDefault();

    if (this.props.createInvoice.generatedInvoiceId != null) {
      const invoiceInput = { ...this.state.invoiceInput };
      invoiceInput[
        "generated_invoice_id"
      ] = this.props.createInvoice.generatedInvoiceId;
      this.setState({
        invoiceInput,
      });
    }

    setTimeout(() => {
      this.props.dispatch(saveInvoiceDetailsStart(this.state.invoiceInput));
    }, 500);
  };

  increaseTableLength = (event) => {
    event.preventDefault();

    this.props.dispatch(saveInvoiceItemsStart(this.state.invoiceItemInput));

    setTimeout(() => {
      this.setState({
        tableLength: this.state.tableLength + 1,
        invoiceItemInput: {
          quantity: "",
          name: "",
          amount: "",
          generated_invoice_id: "",
        },
      });
    }, 1000);
  };

  getItemsInput = ({ currentTarget: input }) => {
    const invoiceItemInput = { ...this.state.invoiceItemInput };
    console.log("invopice", this.props.createInvoice.generatedInvoiceId);
    if (
      this.props.createInvoice.generatedInvoiceId != null &&
      this.state.invoiceItemInput.generated_invoice_id == ""
    ) {
      console.log("terewrwewerwerewr");
      invoiceItemInput[
        "generated_invoice_id"
      ] = this.props.createInvoice.generatedInvoiceId;
    }
    invoiceItemInput[input.name] = input.value;
    this.setState({ invoiceItemInput });
  };

  editSelectedItem = (event, item) => {
    event.preventDefault();
    this.setState({
      selectedItem: item.generated_invoice_item_id,
      selectedItemInput: {
        name: item.name,
        amount: item.amount,
        quantity: item.quantity,
        generated_invoice_item_id: item.generated_invoice_item_id,
        generated_invoice_id: item.generated_invoice_id,
      },
    });
  };

  handleChangeSelectedItem = ({ currentTarget: input }) => {
    const selectedItemInput = { ...this.state.selectedItemInput };
    selectedItemInput[input.name] = input.value;
    this.setState({ selectedItemInput });
  };

  saveSelectedItem = (event) => {
    event.preventDefault();
    this.props.dispatch(saveInvoiceItemsStart(this.state.selectedItemInput));

    this.setState({
      selectedItem: null,
      selectedItemInput: {
        name: "",
        amount: "",
        quantity: "",
        generated_invoice_item_id: "",
        generated_invoice_id: "",
      },
    });
  };

  deleteSelectedItem = (event, item) => {
    event.preventDefault();
    this.props.dispatch(
      deleteInvoiceItemsStart({
        generated_invoice_item_id: item.generated_invoice_item_id,
      })
    );
  };

  // Search to user
  handleChangeUser = ({ currentTarget: input }) => {
    this.setState({ toUserEmail: input.value });
    const invoiceInput = { ...this.state.invoiceInput };
    invoiceInput["to_user_email_address"] = input.value;
    this.setState({ invoiceInput });
    setTimeout(() => {
      if (this.state.toUserEmail.length > 1) {
        this.props.dispatch(
          searchUserStart({ search_key: this.state.toUserEmail })
        );
        this.setState({ displayUserList: "show" });
      }
      if (this.state.toUserEmail.length == 0) {
        this.setState({ displayUserList: "" });
      }
    }, 500);
  };

  chooseToUser = (event, user) => {
    event.preventDefault();
    this.setState({ displayUserList: "", toUserEmail: user.email });
    const invoiceInput = { ...this.state.invoiceInput };
    invoiceInput["to_user_email_address"] = user.email;
    this.setState({ invoiceInput });
  };

  // Search cc user
  handleChangeCCUser = ({ currentTarget: input }) => {
    this.setState({ ccUserEmail: input.value });
    const invoiceInput = { ...this.state.invoiceInput };
    invoiceInput["cc_user_email_address"] = input.value;
    this.setState({ invoiceInput });
    setTimeout(() => {
      if (this.state.ccUserEmail.length > 1) {
        this.props.dispatch(
          searchUserStart({ search_key: this.state.ccUserEmail })
        );
        this.setState({ displayCCUserList: "show" });
      }
      if (this.state.ccUserEmail.length == 0) {
        this.setState({ displayCCUserList: "" });
      }
    }, 500);
  };

  chooseCCUser = (event, user) => {
    event.preventDefault();
    this.setState({ displayCCUserList: "", ccUserEmail: user.email });
    const invoiceInput = { ...this.state.invoiceInput };
    invoiceInput["cc_user_email_address"] = user.email;
    this.setState({ invoiceInput });
  };

  closeSearchUser = (event, type) => {
    event.preventDefault();
    if (type == "toUser") this.setState({ displayUserList: "" });
    if (type == "ccUser") this.setState({ displayCCUserList: "" });
  };

  render() {
    let totalAmount = 0;
    return (
      <Beforeunload onBeforeunload={() => "You'll lose your data!"}>
        <div class="main-wrapper send-money invoice xs-padding">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <h2 class="main-title">Create Invoice</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="invoice-box">
                  <div id="create-invoice-section">
                    <div class="row">
                      <div class="col-md-5">
                        <div class="business-logo">
                          <div class="upload-btn-wrapper">
                            <button class="button">
                              <img
                                src={
                                  this.state.imagePreviewUrl != null
                                    ? this.state.imagePreviewUrl
                                    : window.location.origin +
                                      "/assets/img/business-logo.svg"
                                }
                              />
                              Add your business logo
                            </button>
                            <input
                              type="file"
                              name="logo"
                              accept="image/*"
                              onChange={this.handleChangeImage}
                            />
                          </div>
                        </div>
                        <div
                          class="business-logo"
                          onClick={this.updateBusinessDetails}
                        >
                          <h4 class="business-text">
                            [+] Your Business Information
                          </h4>
                          <a
                            href="#"
                            class="btn btn-quaternary"
                            data-toggle="modal"
                            data-target=".edit-business-modal"
                            id="edit-business-modal"
                            onClick={this.updateBusinessDetails}
                          >
                            Edit
                          </a>
                        </div>
                      </div>

                      <CreateInvoiceRightSec
                        getInvoiceInput={this.getInvoiceInput}
                        invoiceInput={this.state.invoiceInput}
                      />
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-md-12">
                        <div class="bill-to-customer">
                          <form autoComplete="off">
                            <div class="row mb-4">
                              <div class="col-md-1 padding-right-zero">
                                <h4 class="customer-head">Bill to:</h4>
                              </div>
                              <div class="col-md-5 padding-left-zero resp-xs-mrg-btm">
                                <div class="add-customer">
                                  <div class="input-group">
                                    <input
                                      type="text"
                                      class="form-control input-form"
                                      placeholder="Email address or Name"
                                      name="to_user_email_address"
                                      autoComplete="off"
                                      onChange={this.handleChangeUser}
                                      value={this.state.toUserEmail}
                                    />
                                    <div class="input-group-append">
                                      <span class="input-group-text">
                                        <img
                                          src={
                                            window.location.origin +
                                            "/assets/img/people.svg"
                                          }
                                        />
                                      </span>
                                    </div>
                                    <div
                                      className={
                                        this.state.displayUserList == "show"
                                          ? "dropdown-menu sendmoney-dropdown show"
                                          : "dropdown-menu sendmoney-dropdown"
                                      }
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      {this.props.userList.loading ? (
                                        "Loading..."
                                      ) : this.props.userList.data.users
                                          .length > 0 ? (
                                        this.props.userList.data.users.map(
                                          (user) => (
                                            <>
                                              <a
                                                href="#"
                                                onClick={(event) =>
                                                  this.chooseToUser(event, user)
                                                }
                                              >
                                                <div className="send-money-dropdown-content">
                                                  <div className="send-money-user-image">
                                                    <img
                                                      src={user.picture}
                                                      className="user-img"
                                                    />
                                                  </div>
                                                  <div className="send-money-user-details">
                                                    <h5 className="user-name">
                                                      {user.name}
                                                    </h5>
                                                    <p className="text-muted">
                                                      {user.email}
                                                    </p>
                                                  </div>
                                                </div>
                                              </a>
                                              <br />
                                            </>
                                          )
                                        )
                                      ) : (
                                        <>
                                          No match found{" "}
                                          <a
                                            href="#"
                                            onClick={(event) =>
                                              this.closeSearchUser(
                                                event,
                                                "toUser"
                                              )
                                            }
                                          >
                                            <i className="far fa-times-circle"></i>
                                          </a>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-1 padding-right-zero">
                                <h4 class="customer-head">CC:</h4>
                              </div>
                              <div class="col-md-5 padding-left-zero">
                                <div class="add-customer">
                                  <div class="input-group">
                                    <input
                                      type="text"
                                      class="form-control input-form-1"
                                      placeholder="Email address"
                                      autoComplete="off"
                                      name="cc_user_email_address"
                                      onChange={this.handleChangeCCUser}
                                      value={this.state.ccUserEmail}
                                    />
                                    <div
                                      className={
                                        this.state.displayCCUserList == "show"
                                          ? "dropdown-menu sendmoney-dropdown show"
                                          : "dropdown-menu sendmoney-dropdown"
                                      }
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      {this.props.userList.loading ? (
                                        "Loading..."
                                      ) : this.props.userList.data.users
                                          .length > 0 ? (
                                        this.props.userList.data.users.map(
                                          (user) => (
                                            <>
                                              <a
                                                href="#"
                                                onClick={(event) =>
                                                  this.chooseCCUser(event, user)
                                                }
                                              >
                                                <div className="send-money-dropdown-content">
                                                  <div className="send-money-user-image">
                                                    <img
                                                      src={user.picture}
                                                      className="user-img"
                                                    />
                                                  </div>
                                                  <div className="send-money-user-details">
                                                    <h5 className="user-name">
                                                      {user.name}
                                                    </h5>
                                                    <p className="text-muted">
                                                      {user.email}
                                                    </p>
                                                  </div>
                                                </div>
                                              </a>
                                            </>
                                          )
                                        )
                                      ) : (
                                        <>
                                          No match found{" "}
                                          <a
                                            href="#"
                                            onClick={(event) =>
                                              this.closeSearchUser(
                                                event,
                                                "ccUser"
                                              )
                                            }
                                          >
                                            <i className="far fa-times-circle"></i>
                                          </a>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6 padding-right-zero padding-left-zero"></div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <CreateInvoiceTableSec
                      tableLength={this.state.tableLength}
                      getItemsInput={this.getItemsInput}
                      invoiceItemInput={this.state.invoiceItemInput}
                      editSelectedItem={this.editSelectedItem}
                      selectedItem={this.state.selectedItem}
                      invoiceItemsArray={
                        this.props.createInvoice.invoiceItemsArray
                      }
                      selectedItemInput={this.state.selectedItemInput}
                      handleChangeSelectedItem={this.handleChangeSelectedItem}
                      saveSelectedItem={this.saveSelectedItem}
                      invoiceItemsList={
                        this.props.createInvoice.invoiceItemList
                      }
                      deleteSelectedItem={this.deleteSelectedItem}
                    />
                    <div class="row">
                      <div class="col-md-12">
                        <div class="text-center">
                          <a
                            href="#"
                            class="btn btn-quaternary"
                            onClick={this.increaseTableLength}
                          >
                            + Add another Item
                          </a>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-md-12">
                        <div class="total-amount-display">
                          <table class="table table-responsive-sm table-borderless">
                            <tbody>
                              <tr>
                                <td class="desc">Subtotal</td>
                                <td class="desc">
                                  {this.props.createInvoice.invoiceItemList
                                    .loading ? (
                                    "$0"
                                  ) : (
                                    <>
                                      {this.props.createInvoice.invoiceItemList.data.data.invoice_items.map(
                                        (item) => {
                                          totalAmount += item.total;
                                        }
                                      )}
                                      ${totalAmount}
                                    </>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td class="title">Total</td>
                                <td class="title">${totalAmount}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="space-mg"></div>
                    <div class="row">
                      <div class="col-md-6">
                        <form class="note-form" autoComplete="off">
                          <div class="form-group">
                            <label for="note">Note to Recipient</label>
                            <textarea
                              class="form-control"
                              id="note"
                              rows="5"
                              placeholder="For Example “Thank you for the business”"
                              value={this.state.invoiceInput.notes}
                              name="notes"
                              onChange={this.getInvoiceInput}
                            ></textarea>
                          </div>
                        </form>
                      </div>
                      <div class="col-md-6">
                        <form class="note-form" autoComplete="off">
                          <div class="form-group">
                            <label for="terms">Terms and Conditions</label>
                            <textarea
                              class="form-control"
                              id="terms"
                              rows="5"
                              placeholder="Include your return and cancellation policy"
                              value={this.state.invoiceInput.terms_conditions}
                              name="terms_conditions"
                              onChange={this.getInvoiceInput}
                            ></textarea>
                          </div>
                        </form>
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-md-12">
                        <div class="align-right">
                          <button
                            type="submit"
                            class="btn btn-secondary-1 mr-2 resp-xs-mrg-btm disabled"
                            disabled={this.props.createInvoice.buttonDisable}
                            onClick={this.saveInvoiceDetails}
                          >
                            {this.props.createInvoice.loadingButtonContent !=
                            null
                              ? this.props.createInvoice.loadingButtonContent
                              : "Save as draft"}
                          </button>
                          {this.props.createInvoice.generatedInvoiceId !=
                          null ? (
                            <>
                              <Link
                                to={`/preview-invoice/${this.props.createInvoice.generatedInvoiceId}`}
                                class="btn btn-secondary-1 mr-2"
                              >
                                Preview
                              </Link>
                              <button
                                type="submit"
                                class="btn btn-quaternary"
                                disabled={
                                  this.props.createInvoice.sendInvoice
                                    .buttonDisable
                                }
                              >
                                {this.props.createInvoice.sendInvoice
                                  .buttonLoadingContent != null
                                  ? this.props.createInvoice.sendInvoice
                                      .buttonLoadingContent
                                  : "Send"}
                              </button>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BusinessDetailsModel
            getBusinessDetailsData={this.getBusinessDetailsData}
            businessDetailsInput={this.state.businessDetailsInput}
            saveBusinessDetails={this.saveBusinessDetails}
            buttonDisable={this.props.businessDetails.buttonDisable}
            loadingButtonContent={
              this.props.businessDetails.loadingButtonContent
            }
          />
        </div>
      </Beforeunload>
    );
  }
}

const mapStateToPros = (state) => ({
  businessDetails: state.businessDetails,
  createInvoice: state.createInvoice,
  userList: state.sendMoney.userList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CreateInvoiceIndex);
