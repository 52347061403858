import {
    SEND_MONEY_STORE_START,
    SEND_MONEY_STORE_SUCCESS,
    SEND_MONEY_STORE_FAILURE,
    WITHDRAW_MONEY_STORE_START,
    WITHDRAW_MONEY_STORE_SUCCESS,
    WITHDRAW_MONEY_STORE_FAILURE,
  } from "../actions/ActionConstant";
  
const initialState = {  
    sendMoneyStore: {
      data: {},
      loading: true,
      error: false,
      inputData: {},
    },
    withdrawMoneyStore: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
      },
    buttonDisable: false,
    loadingButtonContent: null,
};
  
const StoreReducer = (state = initialState, action) => {
    switch (action.type) {
      
        case SEND_MONEY_STORE_START:
            return {
                ...state,
                sendMoneyStore: {
                    data: {},
                    loading: true,
                    error: false,
                    inputData: action.data,
                },
                buttonDisable: true,
                loadingButtonContent: "Loading...",
            };
        case SEND_MONEY_STORE_SUCCESS:
            return {
                ...state,
                sendMoneyStore: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                },
                buttonDisable: false,
                loadingButtonContent: null,
            };
        case SEND_MONEY_STORE_FAILURE:
            return {
                ...state,
                sendMoneyStore: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                },
                buttonDisable: false,
                loadingButtonContent: null,
            };
        case WITHDRAW_MONEY_STORE_START:
            return {
                ...state,
                withdrawMoneyStore: {
                    data: {},
                    loading: true,
                    error: false,
                    inputData: action.data,
                },
                buttonDisable: true,
                loadingButtonContent: "Loading...",
            };
        case WITHDRAW_MONEY_STORE_SUCCESS:
            return {
                ...state,
                withdrawMoneyStore: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                },
                buttonDisable: false,
                loadingButtonContent: null,
            };
        case WITHDRAW_MONEY_STORE_FAILURE:
            return {
                ...state,
                withdrawMoneyStore: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                },
                buttonDisable: false,
                loadingButtonContent: null,
            };
  
      default:
        return state;
    }
  };
  
  export default StoreReducer;
  