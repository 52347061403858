import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchStoreSentPaymentStart,
  fetchStoreWithdrawPaymentStart,
} from "../../store/actions/TransactionAction";
import { Link } from "react-router-dom";
import EmptyData from "../Handler/EmptyData";

const TransactionActivity = (props) => {
  useEffect(() => {
    if (props.transaction.storeSentPayTrans.loading)
      props.dispatch(fetchStoreSentPaymentStart());
  }, []);
  const paymentReceivedCall = () => {
    if (props.transaction.storeWithdrawPayTrans.loading)
      props.dispatch(fetchStoreWithdrawPaymentStart());
  };

  const { storeSentPayTrans, storeWithdrawPayTrans } = props.transaction;
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="dash-box">
          <div className="dash-info">
            <div className="sub-dash-box">
              <img
                src={
                  window.location.origin + "/assets/img/invoice-blue-icons.svg"
                }
              />
            </div>
            <div className="invoice-details">
              <h5>Transactions</h5>
              <p>Your recent wallet transactions will appear below</p>
            </div>
            {/* <a href="#" className="drop-link">
              <i className="fas fa-ellipsis-v"></i>
            </a> */}
          </div>
          <div className="recent-tabs">
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <a
                  className="nav-item nav-link active"
                  id="nav-payment-sent-tab"
                  data-toggle="tab"
                  href="#nav-payment-sent"
                  role="tab"
                  aria-controls="nav-payment-sent"
                  aria-selected="true"
                >
                  Payment Add
                </a>
                <a
                  className="nav-item nav-link"
                  id="nav-payment-received-tab"
                  data-toggle="tab"
                  href="#nav-payment-received"
                  role="tab"
                  aria-controls="nav-payment-received"
                  aria-selected="false"
                  onClick={paymentReceivedCall}
                >
                  Payment Withdraw
                </a>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="nav-payment-sent"
                role="tabpanel"
                aria-labelledby="nav-payment-sent-tab"
              >
                <table className="table home-table table-responsive-sm table-borderless">
                  <tbody>
                    {storeSentPayTrans.loading
                      ? "Loading..."
                      : storeSentPayTrans.data.history.length > 0
                      ? storeSentPayTrans.data.history.map((transaction, i) => (
                          <tr>
                            <td className="date">{i + 1}</td>
                            <td className="date">{transaction.paid_date}</td>
                            <td className="id">
                              {transaction.user_wallet_payment_unique_id}
                            </td>
                            <td>
                              <div className="trans-details">
                                <div className="user-details">
                                  <h5 className="name">
                                    {transaction.username}
                                  </h5>
                                  <p className="desc">{transaction.message}</p>
                                </div>
                              </div>
                            </td>
                            <td className="positive-amount">
                              {transaction.paid_amount_formatted}
                            </td>
                            <td>
                              <p className="text-dark text-uppercase transaction-status">
                                {transaction.status_formatted}
                              </p>
                            </td>
                          </tr>
                        ))
                      :  <EmptyData />}
                  </tbody>
                </table>
              </div>
              <div
                className="tab-pane fade"
                id="nav-payment-received"
                role="tabpanel"
                aria-labelledby="nav-payment-received-tab"
              >
                <table className="table home-table table-responsive-sm table-borderless">
                  <tbody>
                    {storeWithdrawPayTrans.loading
                      ? "Loading..."
                      : storeWithdrawPayTrans.data.history.length > 0
                      ? storeWithdrawPayTrans.data.history.map((transaction, i) => (
                          <tr>
                            <td className="date">{i + 1}</td>
                            <td className="date">{transaction.paid_date}</td>
                            <td className="id">
                              {transaction.user_wallet_payment_unique_id}
                            </td>
                            <td>
                              <div className="trans-details">
                                <div className="user-details">
                                  <h5 className="name">
                                    {transaction.username}
                                  </h5>
                                  <p className="desc">{transaction.message}</p>
                                </div>
                              </div>
                            </td>
                            <td className="positive-amount">
                              {transaction.paid_amount_formatted}
                            </td>
                            <td>
                              <p className="text-dark text-uppercase transaction-status">
                                {transaction.status_formatted}
                              </p>
                            </td>
                          </tr>
                        ))
                      :  <EmptyData />}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* <div className="home-footer text-right">
            <Link to={"/transaction"} className="link">
              Show More<i className="fas fa-chevron-right ml-1"></i>
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  transaction: state.transaction,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(TransactionActivity);
