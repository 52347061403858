import React, { useState } from "react";
import { connect } from "react-redux";
import EmptyDataTable from "../../Handler/EmptyDataTable";
import _ from "lodash";

const PaymentSentSec = (props) => {
  const { sentPayTrans } = props.transaction;

  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [results, setResults] = useState([]);

  const handleSearchChange = ({ currentTarget: input }) => {
    setIsLoadingSearch(true);
    setTimeout(() => {
      if (input.value < 1) {
        setIsLoadingSearch(false);
        setResults([]);
        return;
      }
      const re = new RegExp(_.escapeRegExp(input.value), "i");
      const isMatch = (result) => re.test(result.username) || re.test(result.user_wallet_payment_unique_id);
      setIsLoadingSearch(false);
      setResults(_.filter(sentPayTrans.data.history, isMatch));
    }, 300);
  };

  let renderData;

  if (results.length > 0) {
    renderData = results;
  } else {
    renderData = sentPayTrans.data.history;
  }

  return (
    <div
      className="tab-pane fade"
      id="nav-payment-sent"
      role="tabpanel"
      aria-labelledby="nav-payment-sent-tab"
    >
      <div className="transactions-table">
        <div className="transactions-table-header flex-data">
          <h3 className="title">Payment Sent</h3>
          <form autoComplete="off">
            <div className="input-group">
              <input
                type="text"
                className="form-control input-form"
                placeholder="Search"
                name="search"
                onChange={handleSearchChange}
                autoComplete="off"
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <i className="fas fa-search"></i>
                </span>
              </div>
            </div>
          </form>
        </div>
        <table className="table trans-table table-responsive-sm table-borderless">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Date</th>
              <th scope="col">Transaction ID</th>
              <th scope="col">Recepient</th>
              <th scope="col">Amount</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {sentPayTrans.loading ? (
              "Loading..."
            ) : renderData.length > 0 ? (
              renderData.map((transaction, i) => (
                <tr>
                  <td className="date">{i + 1}</td>
                  <td className="date">{transaction.paid_date}</td>
                  <td className="id">
                    {transaction.user_wallet_payment_unique_id}
                  </td>
                  <td>
                    <div className="trans-details">
                      <div className="trans-img">
                        <img
                          src={transaction.wallet_picture}
                          className="user-img"
                          alt={transaction.unique_id}
                        />
                      </div>
                      <div className="user-details">
                        <h5 className="name">{transaction.username}</h5>
                        <p className="desc">{transaction.message}</p>
                      </div>
                    </div>
                  </td>
                  <td className="positive-amount">
                    {transaction.paid_amount_formatted}
                  </td>
                  <td>
                    <p className="text-dark text-uppercase transaction-status">
                      {transaction.status_formatted}
                    </p>
                  </td>
                  <td>
                    {transaction.dispute_btn_status == 1 ? (
                      <a
                        data-toggle="modal"
                        data-target=".dispute-form"
                        onClick={() =>
                          props.selectDisputeTransaction(transaction)
                        }
                      >
                        <p className="dispute">Dispute</p>
                      </a>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <EmptyDataTable></EmptyDataTable>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  transaction: state.transaction,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PaymentSentSec);
