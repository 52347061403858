import { call, select, put, takeLatest, all } from "redux-saga/effects";

import api from "../../Environment";
import { SEND_MONEY_STORE_START, WITHDRAW_MONEY_STORE_START } from "../actions/ActionConstant";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
    sendMoneyStoreSuccess,
    sendMoneyStoreFailure,
    withdrawMoneyStoreSuccess,
    withdrawMoneyStoreFailure,
} from "../actions/StoreAction";

function* sendMoneyStoreAPI() {
  try {
    const inputData = yield select(
      (state) => state.store.sendMoneyStore.inputData
    );
    if (!inputData.to_user_id || !inputData.amount) {
      yield put(sendMoneyStoreFailure("Please fill all the details"));
      const notificationMessage = getErrorNotificationMessage(
        "Please fill all the details"
      );
      yield put(createNotification(notificationMessage));
    } else {
      const response = yield api.postMethod("wallets_add_money_by_store_manager", inputData);
      if (response.data.success) {
        yield put(sendMoneyStoreSuccess(response.data.data));
        const notificationMessage = getSuccessNotificationMessage(
          response.data.message
        );
        yield put(createNotification(notificationMessage));
        window.location.assign("/withdraws");
      } else {
        yield put(sendMoneyStoreFailure(response.data.error));
        const notificationMessage = getErrorNotificationMessage(
          response.data.error
        );
        yield put(createNotification(notificationMessage));
      }
    }
  } catch (error) {
    yield put(sendMoneyStoreFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* withdrawMoneyStoreAPI() {
    try {
      const inputData = yield select(
        (state) => state.store.withdrawMoneyStore.inputData
      );
      if (!inputData.to_user_id || !inputData.amount) {
        yield put(withdrawMoneyStoreFailure("Please fill all the details"));
        const notificationMessage = getErrorNotificationMessage(
          "Please fill all the details"
        );
        yield put(createNotification(notificationMessage));
      } else {
        const response = yield api.postMethod("withdraw_money_by_store_manager", inputData);
        if (response.data.success) {
          yield put(withdrawMoneyStoreSuccess(response.data.data));
          const notificationMessage = getSuccessNotificationMessage(
            response.data.message
          );
          yield put(createNotification(notificationMessage));
          window.location.assign("/withdraws");
        } else {
          yield put(withdrawMoneyStoreFailure(response.data.error));
          const notificationMessage = getErrorNotificationMessage(
            response.data.error
          );
          yield put(createNotification(notificationMessage));
        }
      }
    } catch (error) {
      yield put(withdrawMoneyStoreFailure(error));
      const notificationMessage = getErrorNotificationMessage(error.message);
      yield put(createNotification(notificationMessage));
    }
  }

export default function* storeSaga() {
  yield all([
    yield takeLatest(SEND_MONEY_STORE_START, sendMoneyStoreAPI),
    yield takeLatest(WITHDRAW_MONEY_STORE_START, withdrawMoneyStoreAPI),
  ]);
}
