import { combineReducers } from "redux";

import UserReducer from "./UserReducer";
import ChangePasswordReducer from "./ChangePasswordReducer";
import notifyReducer from "react-redux-notify";
import SubscriptionReducer from "./SubscriptionReducer";
import CardsReducer from "./CardsReducer";
import CommonReducer from "./CommonReducer";
import BankAccountReducer from "./BankAccountReducer";
import KycDocumentReducer from "./KycDocumentReducer";
import WalletReducer from "./WalletReducer";
import TransactionReducer from "./TransactionReducer";
import BusinessDetailsReducer from "./BusinessDetailsReducer";
import CreateInvoiceReducer from "./CreateInvoiceReducer";
import ManageInvoiceReducer from "./ManageInvoiceReducer";
import WithDrawReducer from "./WithDrawReducer";
import SendMoneyReducer from "./SendMoneyReducer";
import DisputeReducer from "./DisputeReducer";
import PageReducer from "./PageReducer";
import ErrorReducer from "./ErrorReducer";
import StoreReducer from "./StoreReducer";

export default combineReducers({
  users: UserReducer,
  changePassword: ChangePasswordReducer,
  notifications: notifyReducer,
  subscriptions: SubscriptionReducer,
  cards: CardsReducer,
  common: CommonReducer,
  bankAccount: BankAccountReducer,
  kycDocument: KycDocumentReducer,
  wallet: WalletReducer,
  transaction: TransactionReducer,
  businessDetails: BusinessDetailsReducer,
  createInvoice: CreateInvoiceReducer,
  invoice: ManageInvoiceReducer,
  withDraw: WithDrawReducer,
  sendMoney: SendMoneyReducer,
  dispute: DisputeReducer,
  page: PageReducer,
  errorDetails: ErrorReducer,
  store: StoreReducer,
});
