import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchInvoiceHistoryStart } from "../../../store/actions/ManageInvoiceAction";
import EmptyDataTable from "../../Handler/EmptyDataTable";
import { Link } from "react-router-dom";

const AllInvoiceSec = (props) => {
  const [isFetchingAllTran, setIsFetchingAllTran] = useState(false);
  const [loadMoreData, setLoadMoreData] = useState(true);

  // useEffect(() => {
  //   console.log("callled");
  //   window.addEventListener("scroll", handleScrollAllTrans);
  //   return () => window.removeEventListener("scroll", handleScrollAllTrans);
  // }, []);

  // useEffect(() => {
  //   console.log("callled fetch");
  //   if (!isFetchingAllTran) return;
  //   loadMoreAllTrans();
  // }, [isFetchingAllTran]);

  // function handleScrollAllTrans() {
  //   console.log("called handle");
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop !==
  //       document.documentElement.offsetHeight ||
  //     isFetchingAllTran
  //   )
  //     return;
  //   setIsFetchingAllTran(true);
  // }

  function loadMoreAllTrans() {
    setIsFetchingAllTran(true);
    if (
      props.invoice.invoiceHistory.totalItem !=
      props.invoice.invoiceHistory.data.invoices.length
    ) {
      console.log("Helllosfdfsdsd");
      props.dispatch(fetchInvoiceHistoryStart());
      setTimeout(() => {
        setIsFetchingAllTran(false);
      }, 1000);
    } else {
      setIsFetchingAllTran(false);
      setLoadMoreData(false);
    }
  }

  const { invoiceHistory } = props.invoice;
  return (
    <div
      className="tab-pane fade show active"
      id="nav-all-invoice"
      role="tabpanel"
      aria-labelledby="nav-all-invoice-tab"
    >
      <div className="transactions-table">
        <div className="transactions-table-header flex-data">
          <h3 className="title">Invoice History</h3>
          <form autoComplete="off">
            <div className="input-group">
              <input
                type="text"
                className="form-control input-form"
                placeholder="Search"
                autoComplete="off"
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <i className="fas fa-search"></i>
                </span>
              </div>
            </div>
          </form>
        </div>
        <table className="table trans-table table-responsive-sm table-borderless">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Reference ID</th>
              <th scope="col">Recepient</th>
              <th scope="col">Amount</th>
              <th scope="col">Created</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          {invoiceHistory.loading ? (
            "Loading..."
          ) : invoiceHistory.data.invoices.length > 0 ? (
            <>
              <tbody>
                {invoiceHistory.data.invoices.map((transaction, i) => (
                  <tr>
                    <td className="date">{i + 1}</td>
                    <td className="id">
                      {transaction.generated_invoice_unique_id}
                    </td>
                    <td>
                      <div className="trans-details">
                        <div className="trans-img">
                          <img
                            src={transaction.to_user_picture}
                            className="user-img"
                            alt={transaction.unique_id}
                          />
                        </div>
                        <div className="user-details">
                          <h5 className="name">{transaction.to_username}</h5>
                          <p className="desc">{transaction.message}</p>
                        </div>
                      </div>
                    </td>
                    <td className="positive-amount">
                      {transaction.total_formatted}
                    </td>
                    <td className="date">{transaction.created_at}</td>

                    <td>
                      <p className="text-dark text-uppercase transaction-status">
                        {transaction.status_formatted}
                      </p>
                    </td>
                    <td>
                      <Link
                        to={`/preview-invoice/${transaction.generated_invoice_id}`}
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
              {isFetchingAllTran ? "Fetching more list items..." : null}
              {!loadMoreData ? "No More data" : null}
            </>
          ) : (
            <EmptyDataTable></EmptyDataTable>
          )}
        </table>
        {/* <button
            className="btn btn-lg btn-primary"
            type="submit"
            onClick={() => loadMoreAllTrans()}
          >
            Click Here for Load More
        </button> */}
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  invoice: state.invoice,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AllInvoiceSec);
