import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  SAVE_INVOICE_DETAILS_START,
  FETCH_SINGLE_INVOICE_DETAILS_START,
  SAVE_INVOICE_ITEMS_START,
  SEND_INVOICE_START,
  DELETE_INVOICE_ITEMS_START,
  FETCH_INVOICE_ITEMS_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  saveInvoiceDetailsSuccess,
  saveInvoiceDetailsFailure,
  fetchSingleInvoiceDetailsFailure,
  saveInvoiceItemsSuccess,
  saveInvoiceItemsFailure,
  sendInvoiceSuccess,
  sendInvoiceFailure,
  fetchSingleInvoiceDetailsSuccess,
  deleteInvoiceItemsSuccess,
  deleteInvoiceItemsFailure,
  fetchInvoiceItemStart,
  fetchInvoiceItemSuccess,
  fetchInvoiceItemFailure,
  saveInvoiceId,
} from "../actions/CreateInvoiceAction";

function* saveInvoiceDetailsAPI() {
  try {
    const inputData = yield select(
      (state) => state.createInvoice.invoiceInput.data
    );
    const response = yield api.postMethod(
      "generated_invoices_draft",
      inputData
    );

    if (response.data.success) {
      yield put(saveInvoiceDetailsSuccess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(saveInvoiceId(response.data.data.generated_invoice_id));
    } else {
      yield put(saveInvoiceDetailsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(saveInvoiceDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchInvoiceDetailsAPI() {
  try {
    const inputData = yield select(
      (state) => state.createInvoice.singleInvoiceDetails.invoiceId
    );
    const response = yield api.postMethod("generated_invoices_view", inputData);

    if (response.data.success) {
      yield put(fetchSingleInvoiceDetailsSuccess(response.data));
    } else {
      yield put(fetchSingleInvoiceDetailsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleInvoiceDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* saveInvoiceItemAPI() {
  try {
    const inputData = yield select(
      (state) => state.createInvoice.invoiceItemInput.data
    );

    const response = yield api.postMethod(
      "generated_invoices_items_save",
      inputData
    );

    if (response.data.success) {
      yield put(saveInvoiceItemsSuccess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(
        fetchInvoiceItemStart({
          generated_invoice_id: response.data.data.generated_invoice_id,
        })
      );
      yield put(saveInvoiceId(response.data.data.generated_invoice_id));
    } else {
      yield put(saveInvoiceItemsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(saveInvoiceItemsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchInvoiceItemsAPI() {
  try {
    const inputData = yield select(
      (state) => state.createInvoice.invoiceItemList.inputData
    );
    const response = yield api.postMethod(
      "generated_invoices_items",
      inputData
    );

    if (response.data.success) {
      yield put(fetchInvoiceItemSuccess(response.data));
    } else {
      yield put(fetchInvoiceItemFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchInvoiceItemFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* sendInvoiceAPI() {
  try {
    const inputData = yield select(
      (state) => state.createInvoice.sendInvoice.data
    );
    const response = yield api.postMethod("generated_invoices_send", inputData);

    if (response.data.success) {
      yield put(sendInvoiceSuccess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/manage-invoice");
    } else {
      yield put(sendInvoiceFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(sendInvoiceFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* deleteInvoiceItemAPI() {
  try {
    const inputData = yield select(
      (state) => state.createInvoice.deleteInvoiceItem.deleteInput
    );
    const response = yield api.postMethod(
      "generated_invoices_items_delete",
      inputData
    );

    if (response.data.success) {
      yield put(deleteInvoiceItemsSuccess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(deleteInvoiceItemsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(deleteInvoiceItemsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(SAVE_INVOICE_DETAILS_START, saveInvoiceDetailsAPI),
    yield takeLatest(
      FETCH_SINGLE_INVOICE_DETAILS_START,
      fetchInvoiceDetailsAPI
    ),
    yield takeLatest(SAVE_INVOICE_ITEMS_START, saveInvoiceItemAPI),
    yield takeLatest(SEND_INVOICE_START, sendInvoiceAPI),
    yield takeLatest(DELETE_INVOICE_ITEMS_START, deleteInvoiceItemAPI),
    yield takeLatest(FETCH_INVOICE_ITEMS_START, fetchInvoiceItemsAPI),
  ]);
}
