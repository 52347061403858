import React, { Component } from "react";
import WalletDepositSec from "./WalletDepositSec";
import WalletWithdrawSec from "./WalletWithdrawSec";
import { connect } from "react-redux";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";
import { Link } from "react-router-dom";
import WithdrawModel from "./WithdrawModel";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";

class WalletIndex extends Component {
  state = {};

  componentDidMount() {
    if (this.props.wallet.walletData.loading) {
      this.props.dispatch(fetchWalletDetailsStart());
    }
    if (this.props.profile.loading) {
      this.props.dispatch(fetchUserDetailsStart());
    }
  }

  render() {
    const { walletData } = this.props.wallet;

    return (
      <div class="main-wrapper wallet xs-padding">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h2 class="main-title">Wallet</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="wallet-box">
                <div class="row">
                  <div class="col-md-12">
                    <h4>Wallet Balance</h4>
                    <h2>
                      {walletData.loading
                        ? "Loading..."
                        : walletData.data.user_wallet.remaining_formatted}
                    </h2>
                    {this.props.profile.loading ? (
                      "Loading..."
                    ) : this.props.profile.data.is_kyc_document_approved ==
                      2 ? (
                      <>
                        <Link
                          to={"/add-money"}
                          class="btn btn-teritory mr-3 resp-xs-mrg-btm"
                        >
                          Add Money
                        </Link>
                        <a
                          href="#"
                          class="btn btn-quaternary"
                          data-toggle="modal"
                          data-target="#withdraw-modal"
                        >
                          Withdraw Money
                        </a>
                      </>
                    ) : (
                      <>
                        <Link
                          to={"#"}
                          disabled={true}
                          class="btn btn-teritory mr-3 resp-xs-mrg-btm"
                        >
                          Add Money
                        </Link>
                        <a href="#" class="btn btn-quaternary">
                          Withdraw Money
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="space-lg"></div>
          <div class="row">
            <WalletDepositSec />
            <WalletWithdrawSec />
          </div>
          <div className="space-center"></div>
        </div>
        <WithdrawModel />
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  wallet: state.wallet,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(WalletIndex);
