import React, { Component } from "react";
import { connect } from "react-redux";
import { displayLogin } from "../../store/actions/CommonAction";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";

import {
  getRegisterInputData,
  userRegisterStart,
} from "../../store/actions/UserAction";
const $ = window.$;

class RegisterIndex extends Component {
  handleChange = ({ currentTarget: input }) => {
    this.props.dispatch(getRegisterInputData(input.name, input.value));
  };

  showLogin = (event) => {
    $('#registerModal').modal('hide');
    this.props.dispatch(displayLogin());
  };

  handleRegisterSubmit = (event) => {
    event.preventDefault();
    this.props.dispatch(userRegisterStart());
  };

  render() {
    const inputData = this.props.inputData.registerInputData;
    const showSignup = this.props.common.displaySignup ? "show" : "";
    return (
      <div
        className={"modal fade signup signup-sec " + showSignup}
        role="dialog"
        id="registerModal"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="close btn-close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body">
              <div className="c-box">
                <div className="row">
                  <div className="col-md-6">
                    <Link to="/" className="l-logo">
                      <img
                        src={configuration.get("configData.site_logo")}
                        alt={configuration.get("configData.site_name")}
                      />
                    </Link>
                    <div className="signup-content">
                      <h4 className="title">Sign Up</h4>
                      <p className="desc">
                      Discover the different ways for you to get the most out of your PayPal account. Take charge of your money and purchases today.
                      </p>
                      <form autoComplete="off">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control input-form"
                            placeholder="First Name"
                            name="first_name"
                            value={inputData.data.first_name}
                            onChange={this.handleChange}
                            autoComplete="off"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="fas fa-user"></i>
                            </span>
                          </div>
                        </div>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control input-form"
                            placeholder="Last Name"
                            name="last_name"
                            value={inputData.data.last_name}
                            onChange={this.handleChange}
                            autoComplete="off"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="fas fa-user"></i>
                            </span>
                          </div>
                        </div>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control input-form"
                            placeholder="Email"
                            name="email"
                            value={inputData.data.email}
                            onChange={this.handleChange}
                            autoComplete="off"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="fas fa-envelope"></i>
                            </span>
                          </div>
                        </div>
                        <div className="input-group mb-4">
                          <input
                            type="password"
                            className="form-control input-form"
                            placeholder="Password"
                            name="password"
                            value={inputData.data.password}
                            onChange={this.handleChange}
                            autoComplete="off"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="fas fa-lock"></i>
                            </span>
                          </div>
                        </div>
                        <button
                          className="btn btn-primary mb-3"
                          href="#"
                          onClick={this.handleRegisterSubmit}
                          disabled={this.props.inputData.buttonDisable}
                        >
                          {this.props.inputData.loadingButtonContent != null
                            ? this.props.inputData.loadingButtonContent
                            : "Register"}
                        </button>
                      </form>
                      <h6 className="resp-z-index">
                        Already have an account?
                        <span className="ml-2">
                          <a
                            href="#"
                            data-toggle="modal"
                            data-target=".login"
                            onClick={this.showLogin}
                          >
                            Login
                          </a>
                        </span>
                      </h6>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="signup-right-info">
                      <div className="l-main-img-sec">
                        <img
                          src={
                            window.location.origin + "/assets/img/login-img.svg"
                          }
                          className="l-main-img"
                        />
                      </div>
                      <div className="l-bg-img-sec">
                        <img
                          src={
                            window.location.origin + "/assets/img/lock-icon.svg"
                          }
                          className="l-bg-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  inputData: state.users,
  common: state.common,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(RegisterIndex);
