import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchUserDetailsStart,regenerateMerchantKeyStart } from "../../../store/actions/UserAction";
import { Link } from "react-router-dom";

const MerchantDetailsIndex = (props) => {
    useEffect(() => {
        if (props.profile.loading) props.dispatch(fetchUserDetailsStart());
    }, []);
    

    const regenerateMerchantKey = (event) => {
        event.preventDefault();
        props.dispatch(regenerateMerchantKeyStart());
    };

    return (
        <div className="main-wrapper change-password xs-padding">
            <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h2 className="main-title">Merchant Details</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                <div className="change-password-box">
                    <div className="row">
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-12">
                                <p className="main-title">API Keys</p>
                            </div>
                            <div className="col-md-12">
                                <p>Created At: {props.profile.data.merchant_key_created_at}</p>
                            </div>
                            <div className="form-group row flex-data">
                                <div className="col-md-4">
                                    <label for="new-pass">Merchant Id</label>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        value={props.profile.data.merchant_id}
                                        className="form-control"
                                        disabled
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="col-md-2"></div>
                            </div>
                            <div className="form-group row flex-data">
                                <div className="col-md-3">
                                    <label for="new-pass">Merchant Key</label>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        value={props.profile.data.merchant_key}
                                        className="form-control"
                                        disabled
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="col-md-2">
                                    <Link
                                    className="mt-2"
                                    to="#"
                                    onClick={(event) =>
                                        regenerateMerchantKey(event)
                                    }
                                    >
                                        <span className="badge badge-default1">
                                            Regerate key
                                        </span>
                                    </Link>
                                </div>
                                
                            </div>

                            
                        </div>
                    </div>
                    <div className="col-md-4">
                        <img
                        src={
                            window.location.origin + "/assets/img/send-money.svg"
                        }
                        className="full-img"
                        />
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="space-center space-center-1"></div>
            </div>
        </div>
    );
}

const mapStateToPros = (state) => ({
    profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MerchantDetailsIndex);
