import React, { Component } from "react";
import DashboardMoneySec from "./DashboardMoneySec";
import DashboardInvoiceSec from "./DashboardInvoiceSec";
import UnpaidInvoiceSec from "./UnpaidInvoiceSec";
import DraftSec from "./DraftSec";
import RecentActivity from "./RecentActivitySec";
import { connect } from "react-redux";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";
import WithdrawModel from "../Wallet/WithdrawModel";
import { saveInvoiceDetailsStart } from "../../store/actions/CreateInvoiceAction";

class DashboardIndex extends Component {
  state = {};
  componentDidMount() {
    if (this.props.wallet.walletData.loading) {
      this.props.dispatch(fetchWalletDetailsStart());
    }
  }

  render() {
    return (
      <div className="main-wrapper home xs-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card-style">
                <h2 className="main-title mb-0">Summary</h2>
                {/* <button className="btn btn-primary">More</button> */}
              </div>
            </div>
          </div>
          <div className="row">
            <DashboardMoneySec />
            <DashboardInvoiceSec />
          </div>
          {/* <div className="space-lg"></div>
          <UnpaidInvoiceSec /> */}
          <div className="space-lg"></div>
          <DraftSec />
          <div className="space-lg"></div>
          <RecentActivity />
          <div className="space-center"></div>
        </div>
        <WithdrawModel />
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  wallet: state.wallet,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(DashboardIndex);
