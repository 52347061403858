import React, { Component } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";

import {
  displayLogin,
  displaySignup,
} from "../../../store/actions/CommonAction";

class AuthHeader extends Component {
  handleLogin = (event) => {
    event.preventDefault();
    this.props.dispatch(displayLogin());
  };
  handleSignup = (event) => {
    event.preventDefault();
    this.props.dispatch(displaySignup());
  };

  render() {
    return (
      <header className="landing-header">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarTogglerDemo01"
              aria-controls="navbarTogglerDemo01"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <Link className="navbar-brand" to="/">
              <img
                src={configuration.get("configData.site_logo")}
                className="logo"
                alt={configuration.get("configData.site_name")}
              />
            </Link>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
              <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                {localStorage.getItem("userLoginStatus") == "true" ? (
                  <li className="nav-item">
                    <Link className="nav-link" to="/dashboard">
                      Dashboard
                    </Link>
                  </li>
                ) : (
                  <li className="nav-item active">
                    <Link className="nav-link" to="/">
                      Home
                    </Link>
                  </li>
                )}
                <li className="nav-item">
                  <Link className="nav-link" to="/page/business">
                    Business
                  </Link>
                </li>
                <li className="nav-item mr-xs">
                  <Link className="nav-link" to="/page/help">
                    Help Center
                  </Link>
                </li>
                {localStorage.getItem("userLoginStatus") == "true" ? (
                  <li className="nav-item">
                    <Link className="nav-link" to="/profile">
                      Welcome, {localStorage.getItem("username")}
                    </Link>
                  </li>
                ) : (
                  <>
                    <li className="mr-sm resp-mrg-btm">
                      <a
                        className="btn btn-primary"
                        data-toggle="modal"
                        data-target=".login"
                        href="#"
                        onClick={this.handleLogin}
                      >
                        Login
                      </a>
                    </li>
                    <li className="">
                      <a
                        className="btn btn-secondary"
                        data-toggle="modal"
                        data-target=".signup"
                        href="#"
                        onClick={this.handleSignup}
                      >
                        Sign Up
                      </a>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

const mapStateToPros = (state) => ({
  common: state.common,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AuthHeader);
