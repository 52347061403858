import {
    SEND_MONEY_STORE_START,
    SEND_MONEY_STORE_SUCCESS,
    SEND_MONEY_STORE_FAILURE,
    WITHDRAW_MONEY_STORE_START,
    WITHDRAW_MONEY_STORE_SUCCESS,
    WITHDRAW_MONEY_STORE_FAILURE,
  } from "./ActionConstant";
  
  
  export function sendMoneyStoreStart(data) {
    return {
      type: SEND_MONEY_STORE_START,
      data,
    };
  }
  
  export function sendMoneyStoreSuccess(data) {
    return {
      type: SEND_MONEY_STORE_SUCCESS,
      data,
    };
  }
  
  export function sendMoneyStoreFailure(error) {
    return {
      type: SEND_MONEY_STORE_FAILURE,
      error,
    };
  }

  export function withdrawMoneyStoreStart(data) {
    return {
      type: WITHDRAW_MONEY_STORE_START,
      data,
    };
  }
  
  export function withdrawMoneyStoreSuccess(data) {
    return {
      type: WITHDRAW_MONEY_STORE_SUCCESS,
      data,
    };
  }
  
  export function withdrawMoneyStoreFailure(error) {
    return {
      type: WITHDRAW_MONEY_STORE_FAILURE,
      error,
    };
  }
  