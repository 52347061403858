import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchUserDetailsStart,
  editUserDetails,
  updateUserDetailsStart,
} from "../../../store/actions/UserAction";

class EditProfile extends Component {
  state = {
    loading: true,
    inputData: {},
    imagePreviewUrl: null,
  };
  componentDidMount() {
    if (this.props.userData.profile.loading)
      this.props.dispatch(fetchUserDetailsStart());
  }
  handleChange = ({ currentTarget: input }) => {
    this.props.dispatch(editUserDetails(input.name, input.value));
  };
  handleChangeImage = ({ currentTarget: input }) => {
    const inputData = { ...this.state.inputData };
    if (input.type === "file") {
      inputData[input.name] = input.files[0];
      this.setState({ inputData });
    }
    let reader = new FileReader();
    let file = input.files[0];

    reader.onloadend = () => {
      if (input.name == "picture")
        this.setState({
          imagePreviewUrl: reader.result,
        });
    };
    if (file) {
      reader.readAsDataURL(file);
      setTimeout(() => {
        this.props.dispatch(updateUserDetailsStart(input.files[0]));
      }, 1000);
    }
  };
  updateProfile = (event) => {
    event.preventDefault();
    this.props.dispatch(updateUserDetailsStart());
  };
  render() {
    const userData = this.props.userData.profile;
    if (userData.loading === true) {
      return "Loading...";
    } else {
      return (
        <div className="main-wrapper edit-profile xs-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="main-title">Edit Profile</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="edit-profile-box">
                  <div className="row">
                    <div className="col-md-5 resp-sm-mrg-btm">
                      <div className="edit-profile-details">
                        <div className="edit-profile-img mb-3">
                          <img
                            src={
                              this.state.imagePreviewUrl != null
                                ? this.state.imagePreviewUrl
                                : userData.data.picture
                            }
                            className="ep-img"
                          />
                        </div>
                        <div className="upload-btn-wrapper">
                          <button className="button">Upload a file</button>
                          <input
                            type="file"
                            accept="image/*"
                            name="picture"
                            onChange={this.handleChangeImage}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="edit-profile-info">
                        <h2 className="title-head">Update Details</h2>
                        <form autoComplete="off">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control input-form"
                              placeholder="First Name"
                              value={userData.data.first_name}
                              name="first_name"
                              autoComplete="off"
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control input-form"
                              placeholder="Last Name"
                              value={userData.data.last_name}
                              name="last_name"
                              autoComplete="off"
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control input-form"
                              placeholder="Email"
                              name="email"
                              autoComplete="off"
                              value={userData.data.email}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control input-form"
                              placeholder="Enter Phone Number"
                              name="mobile"
                              value={userData.data.mobile}
                              onChange={this.handleChange}
                              autoComplete="off"
                            />
                          </div>
                          <div className="form-group">
                            <textarea
                              className="form-control input-form"
                              placeholder="Address"
                              rows="3"
                              name="address"
                              value={userData.data.address}
                              onChange={this.handleChange}
                              autoComplete="off"
                            ></textarea>
                          </div>
                        </form>
                        <div className="resp-align-center">
                          <button
                            type="submit"
                            className="btn btn-quaternary"
                            onClick={this.updateProfile}
                            disabled={this.props.userData.buttonDisable}
                          >
                            {this.props.userData.loadingButtonContent != null
                              ? this.props.userData.loadingButtonContent
                              : "Update Profile"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="space-center"></div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToPros = (state) => ({
  userData: state.users,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(EditProfile);
