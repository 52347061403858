import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchSingleInvoiceDetailsStart,
  sendInvoiceStart,
} from "../../../store/actions/CreateInvoiceAction";
import { invoicePayNowStart } from "../../../store/actions/ManageInvoiceAction";

class PreviewInvoiceIndex extends Component {
  state = {
    total: 0,
    makePayment: false,
  };

  componentDidMount() {
    this.props.dispatch(
      fetchSingleInvoiceDetailsStart({
        generated_invoice_id: this.props.match.params.id,
      })
    );
    if (this.props.location.state == "received") {
      this.setState({ makePayment: true });
    }
  }

  sendInvoice = (event) => {
    event.preventDefault();
    this.props.dispatch(
      sendInvoiceStart({ generated_invoice_id: this.props.match.params.id })
    );
  };

  payNow = (event) => {
    event.preventDefault();
    this.props.dispatch(
      invoicePayNowStart({ generated_invoice_id: this.props.match.params.id })
    );
  };

  render() {
    let totalAmount = 0;
    const { singleInvoiceDetails } = this.props.invoice;
    if (singleInvoiceDetails.loading) {
      return "Loading....";
    } else {
      return (
        <div class="main-wrapper invoice xs-padding">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <h2 class="main-title">Invoices</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="invoice-box">
                  <div class="tab-content" id="nav-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="nav-create-invoice"
                      role="tabpanel"
                      aria-labelledby="nav-create-invoice-tab"
                    >
                      <div class="row">
                        <div class="col-md-5 full-lg-width resp-ld-margin-btm">
                          <div class="preview-details">
                            <img
                              className="invoice-logo"
                              src={
                                singleInvoiceDetails.data.data.invoice_details
                                  .logo
                              }
                            />
                            <h3 class="preview-title">
                              {singleInvoiceDetails.data.data
                                .invoice_billing_info != null
                                ? singleInvoiceDetails.data.data
                                    .invoice_billing_info.business_name
                                : null}
                            </h3>
                            <p class="preview-address">
                              {singleInvoiceDetails.data.data
                                .invoice_billing_info != null
                                ? singleInvoiceDetails.data.data
                                    .invoice_billing_info.address
                                : null}
                            </p>
                            <div class="flex-new-data">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="flex-space-equal">
                                    <p class="preview-no">Business Name</p>
                                    <p class="preview-no">:</p>
                                  </div>
                                  <div class="flex-space-equal">
                                    <p class="preview-no">Phone Number</p>
                                    <p class="preview-no">:</p>
                                  </div>
                                  <div class="flex-space-equal">
                                    <p class="preview-email">Email </p>
                                    <p class="preview-no">:</p>
                                  </div>
                                  <div class="flex-space-equal">
                                    <p class="preview-email">Website </p>
                                    <p class="preview-no">:</p>
                                  </div>
                                  <div class="flex-space-equal">
                                    <p class="preview-email">Tax ID</p>
                                    <p class="preview-no">:</p>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <p class="desc">
                                    {singleInvoiceDetails.data.data
                                      .invoice_billing_info != null
                                      ? singleInvoiceDetails.data.data
                                          .invoice_billing_info.first_name +
                                        " " +
                                        singleInvoiceDetails.data.data
                                          .invoice_billing_info.last_name
                                      : "-"}
                                  </p>

                                  <p class="desc">
                                    {singleInvoiceDetails.data.data
                                      .invoice_billing_info != null
                                      ? singleInvoiceDetails.data.data
                                          .invoice_billing_info.mobile
                                      : "-"}
                                  </p>
                                  <p class="desc">
                                    {singleInvoiceDetails.data.data
                                      .invoice_billing_info != null
                                      ? singleInvoiceDetails.data.data
                                          .invoice_billing_info.email
                                      : "-"}
                                  </p>
                                  <p class="desc">
                                    {singleInvoiceDetails.data.data
                                      .invoice_billing_info != null
                                      ? singleInvoiceDetails.data.data
                                          .invoice_billing_info.website
                                      : "-"}
                                  </p>
                                  <p class="desc">
                                    {singleInvoiceDetails.data.data
                                      .invoice_billing_info != null
                                      ? singleInvoiceDetails.data.data
                                          .invoice_billing_info.tax_number
                                      : "-"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-7 full-lg-width">
                          <div class="preview-info">
                            <div class="row offset-md-3">
                              <div class="col-md-6">
                                <div class="flex-space-equal">
                                  <p class="title">Invoice Number</p>
                                  <p class="title">:</p>
                                </div>
                                <div class="flex-space-equal">
                                  <p class="title">Invoice Date</p>
                                  <p class="title">:</p>
                                </div>
                                <div class="flex-space-equal">
                                  <p class="title">Reference</p>
                                  <p class="title">:</p>
                                </div>
                                <div class="flex-space-equal">
                                  <p class="title">Due Date</p>
                                  <p class="title">:</p>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <p class="desc">
                                  {singleInvoiceDetails.data.data
                                    .invoice_details.invoice_number
                                    ? singleInvoiceDetails.data.data
                                        .invoice_details.invoice_number
                                    : "-"}
                                </p>
                                <p class="desc">
                                  {singleInvoiceDetails.data.data
                                    .invoice_details.invoice_date
                                    ? singleInvoiceDetails.data.data
                                        .invoice_details.invoice_date_formatted
                                    : "-"}
                                </p>
                                <p class="desc">
                                  {singleInvoiceDetails.data.data
                                    .invoice_details.reference
                                    ? singleInvoiceDetails.data.data
                                        .invoice_details.reference
                                    : "-"}
                                </p>
                                <p class="desc">
                                  {
                                    singleInvoiceDetails.data.data
                                      .invoice_details.due_date_formatted
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-md-12">
                          <div class="bill-to-customer-info">
                            <div class="mb-2">
                              <h4 class="title">Bill to:</h4>
                              <p class="desc">
                                {singleInvoiceDetails.data.data.invoice_details
                                  .to_user_email_address
                                  ? singleInvoiceDetails.data.data
                                      .invoice_details.to_user_email_address
                                  : "N/A"}
                              </p>
                            </div>
                            <div>
                              <h4 class="title">CC:</h4>
                              <p class="desc">
                                {singleInvoiceDetails.data.data.invoice_details
                                  .cc_user_email_address
                                  ? singleInvoiceDetails.data.data
                                      .invoice_details.cc_user_email_address
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="invoice-custom-center-table">
                        <table class="table invoice-custom-table tabel-details-info table-responsive-sm table-borderless">
                          <thead>
                            <tr>
                              <th scope="col">Description</th>
                              <th scope="col">Price</th>
                              <th scope="col">Quantity</th>
                              <th scope="col">Line Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {singleInvoiceDetails.data.data.invoice_items.map(
                              (items) => (
                                <tr>
                                  <td>{items.name}</td>
                                  <td>{items.amount_formatted}</td>
                                  <td>{items.quantity}</td>
                                  <td>{items.total_formatted}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-md-6">
                          <div className="align-left">
                            <p>
                              <b>Notes</b>
                            </p>
                            <p className="text-muted">
                              {
                                singleInvoiceDetails.data.data.invoice_details
                                  .notes
                              }
                            </p>
                          </div>
                          <div className="align-left">
                            <p>
                              <b>Terms</b>
                            </p>

                            <p className="text-muted">
                              {singleInvoiceDetails.data.data.invoice_details
                                .terms_conditions
                                ? singleInvoiceDetails.data.data.invoice_details
                                    .terms_conditions
                                : "N/A"}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="total-amount-display">
                            <table class="table table-responsive-sm table-borderless">
                              <tbody>
                                {/* <tr>
                                  <td class="desc">Subtotal</td>
                                  <td class="desc">$105.00</td>
                                </tr> */}
                                <tr>
                                  <td class="title">Total</td>

                                  <td class="title">
                                    {singleInvoiceDetails.loading ? (
                                      "$0"
                                    ) : (
                                      <>
                                        {singleInvoiceDetails.data.data.invoice_items.map(
                                          (item) => {
                                            totalAmount += item.total;
                                          }
                                        )}
                                        ${totalAmount}
                                      </>
                                    )}
                                  </td>
                                  {/* </tr>
                                <tr>
                                  <td class="title">Amout Paid</td>
                                  <td class="title">$105.00</td>
                                </tr>
                                <tr>
                                  <td class="title-main">Amount Due</td>
                                  <td class="title-main">$105.00</td>*/}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="space-mg"></div>
                      <hr />
                      <div class="row">
                        <div class="col-md-12">
                          <div class="align-right">
                            {this.state.makePayment &&
                            singleInvoiceDetails.data.data
                              .payment_btn_status ? (
                              <a
                                href="#"
                                class="btn btn-quaternary"
                                onClick={this.payNow}
                              >
                                Pay Now
                              </a>
                            ) : singleInvoiceDetails.data.data
                                .send_btn_status ? (
                              <a
                                href="#"
                                class="btn btn-quaternary"
                                onClick={this.sendInvoice}
                              >
                                Send
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToPros = (state) => ({
  invoice: state.createInvoice,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PreviewInvoiceIndex);
