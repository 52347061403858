import React, { Component } from "react";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";

const $ = window.$;
class AuthFooter extends Component {
  state = {};

  componentDidMount() {
        var footerHeight = $("#landing-footer").outerHeight();
        var deviceheight = $(window).outerHeight();
        var contentheight = deviceheight - footerHeight - 70;
        $(".landing-main-wrapper").css("min-height", contentheight);
    }
  render() {
    return (
      <div className="footer-fixed sm-padding-top" id="landing-footer">
        <footer className="footer-sec sm-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <h5 className="footer-logo">
                  {configuration.get("configData.site_name")}
                </h5>
                <p className="desc">
                  {configuration.get("configData.tag_name")}
                </p>
                <div className="socialbtns">
                  <ul>
                    <li className="mr-1">
                      <Link to={configuration.get("configData.facebook_link")}>
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li className="mr-1">
                      <Link to={configuration.get("configData.twitter_link")}>
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li>
                    <li className="mr-1">
                      <Link to={configuration.get("configData.instagram_link")}>
                        <i className="fab fa-instagram"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to={configuration.get("configData.linkedin_link")}>
                        <i className="fab fa-linkedin-in"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 resp-sm-mrg-btm">
                <h6 className="title">Company</h6>
                <ul className="list-unstyled footer-link">
                  {configuration
                    .get("configData.footer_pages1")
                    .map((static_page, index) => (
                      <li
                        className={index == 0 ? "resp-xs-pad-top" : ""}
                        key={static_page.unique_id}
                      >
                        <Link
                          to={`/page/${static_page.unique_id}`}
                          key={static_page.page_id}
                        >
                          {static_page.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="col-md-3 resp-sm-mrg-btm">
                <h6 className="title">Support</h6>
                <ul className="list-unstyled footer-link">
                  {configuration
                    .get("configData.footer_pages2")
                    .map((static_page, index) => (
                      <li
                        className={index == 0 ? "resp-xs-pad-top" : ""}
                        key={static_page.unique_id}
                      >
                        <Link
                          to={`/page/${static_page.unique_id}`}
                          key={static_page.page_id}
                        >
                          {static_page.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="col-md-3 footer-link">
                <h6 className="title">Address</h6>
                <p className="resp-xs-pad-top">
                  <span>
                    <b>Location:</b>
                  </span>{" "}
                  {configuration.get("configData.contact_address")}
                </p>
                <p>
                  <span>
                    <b>Email:</b>
                  </span>{" "}
                  {configuration.get("configData.contact_email")}
                </p>
                <p>
                  <span>
                    <b>Phone:</b>
                  </span>{" "}
                  {configuration.get("configData.contact_mobile")}
                </p>
              </div>
            </div>
          </div>
        </footer>
        <div className="footer-sub">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3>{configuration.get("configData.copyrights_content")}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AuthFooter;
