import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchUserDetailsStart } from "../../../store/actions/UserAction";


class QrCodeIndex extends Component {

    componentDidMount() {
        if (this.props.profile.loading) {
          this.props.dispatch(fetchUserDetailsStart());
        }
    }
    render() {
        console.log(this.props.profile.data);
        return (
            <div className="main-wrapper change-password xs-padding">
                <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="main-title">QR Code</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                    <div className="change-password-box">
                        <div className="row">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="main-title">Paynow with QR Code</p>
                                </div>
                                <div className="col-md-12">
                                    {this.props.profile.loading ? (
                                        "Loading..."
                                    ) : this.props.profile.data ? (
                                        <img
                                            src={this.props.profile.data.qr_code}
                                            className=""
                                        />

                                    ) : ''}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img
                            src={
                                window.location.origin + "/assets/img/send-money.svg"
                            }
                            className="full-img"
                            />
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="space-center space-center-1"></div>
                </div>
            </div>
        );
    }
}

const mapStateToPros = (state) => ({
    profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(QrCodeIndex);
